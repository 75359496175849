import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { errorToast } from "../Toast-Messages/toastMessage";

export const getBanksList = createAsyncThunk(
  "getAllBanksListFunc",
  async (_, { rejectWithValue, dispatch }) => {
    const url = `/banks/list`;

    try {
      const res = await axios.get(url);
      // console.log(res);
      return res.data;
    } catch (error) {
      // console.log(error.response);
      if (error.response && error.response.data) {
        if (Array?.isArray(error.response.data.message)) {
          error.response.data.message &&
            error.response.data.message?.map((item) =>
              dispatch(errorToast(item))
            );
        } else {
          dispatch(errorToast(error.response.data.message));
        }
        return rejectWithValue(error.response.data.message);
      } else if (navigator.onLine === false) {
        dispatch(errorToast("Check Internet Connection"));
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  allBanks: [],
  error: null,
};
export const BankSlice = createSlice({
  name: "BankSlice-Func",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getBanksList.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })

      .addCase(getBanksList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.allBanks = action.payload;
        // console.log(action.payload);
      })

      .addCase(getBanksList.rejected, (state, action) => {
        state.loading = false;
        state.allBanks = [];
        state.error = action.payload;
      });
  },
});

// export const {} = BankSlice.actions

export default BankSlice.reducer;

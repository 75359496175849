import { configureStore } from "@reduxjs/toolkit";
import overlaySlice from "./Slice/common/overlaySlice";
import toastMessage from "./Slice/Toast-Messages/toastMessage";
import auth from "./Slice/Auth/auth";
import profileSlice from "./Slice/Profile/profileSlice";
import users from "./Slice/Users/usersSlice";
import changePasswordSlice from "./Slice/Profile/changePasswordSlice";
import toggleActiveSlice from "./Slice/Users/toggleActiveSlice";
import myRestaurant from "./Slice/MyRestaurant/myRestaurant";
import tags from "./Slice/Tags/tags";
import cuisines from "./Slice/Cuisines/cuisines";
import mealsType from "./Slice/MealsType/mealsType";
import branches from "./Slice/Branches/branches";
import menu from "./Slice/Menu/menu";
import menuById from "./Slice/Menu/menuById";
import rating from "./Slice/Rating/rating";
import orders from "./Slice/Orders/orders";
import offers from "./Slice/Offers/offers";
import file from "./Slice/Upload/file";
import deals from "./Slice/Deals/deals";
import report from "./Slice/Report/report";
import multiStepForm from "./Slice/MultiStepForm/multiStepFormSlice";
import roles from "./Slice/Roles/roles";
import mapSlice from "./Slice/common/mapSlice";
import resetPagination from "./Slice/ResetPagination/resetPagination";
import packages from "./Slice/Packages/packages";
import banks from "./Slice/Banks/banks";

export const store = configureStore({
  reducer: {
    overlay: overlaySlice,
    toastMessage: toastMessage,
    auth: auth,
    profile: profileSlice,
    users: users,
    changepassword: changePasswordSlice,
    activeStatus: toggleActiveSlice,
    myRestaurant: myRestaurant,
    tags: tags,
    cuisines: cuisines,
    mealsType: mealsType,
    branches: branches,
    categories: menu,
    menuItem: menuById,
    rate: rating,
    orders: orders,
    offers: offers,
    file: file,
    deals: deals,
    report: report,
    multiStepForm: multiStepForm,
    roles: roles,
    map: mapSlice,
    resetPagination: resetPagination,
    packages: packages,
    banks: banks,
  },
});

import { BiReset } from "react-icons/bi";
import { Autocomplete, Box, Button, Chip, FormControl, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdOutlineModeEditOutline } from "react-icons/md";
import previewIcon from '../../Assets/imgs/previewIcon.svg'
import { useDispatch, useSelector } from "react-redux";
import { editMyRestaurant, getDeliveryTypes, getMyRestaurant, setWorkingHours } from "../../Components/Redux/Slice/MyRestaurant/myRestaurant";
import { getTags } from "../../Components/Redux/Slice/Tags/tags";
import { getcuisines } from "../../Components/Redux/Slice/Cuisines/cuisines";
import { getMealsType } from "../../Components/Redux/Slice/MealsType/mealsType";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addFile } from "../../Components/Redux/Slice/Upload/file";
import Overlay from '../../Components/Common/overlay'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Astrisks from '../../Components/Common/astrisk'
import dayjs from "dayjs";
import { isValidIBAN } from "../../Components/Common/ibanValidation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import bannerDef from '../../Assets/imgs/Banner.svg'
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Compressor from "compressorjs";
import { getBanksList } from "../../Components/Redux/Slice/Banks/banks";

const Settings = () => {
    const { t, i18n } = useTranslation()
    const MySwal = withReactContent(Swal);
    const form = useForm();
    const { register, control, handleSubmit: handleSubmitForm1, formState: formState1, setValue: setValue1, getValues: getValues1 } = form;

    // const form2 = useForm();
    // const { register: registerForm2, control:control2, handleSubmit: handleSubmitForm2, formState : formState2, setValue : setValue2, getValues : getValues2 } = form2;

    // const form = useForm()
    // const {register,control,handleSubmit,formState,setValue,getValues} = form
    const { errors, isDirty } = formState1

    const { myRestaurant } = useSelector(state => state.myRestaurant)
    const { deliveryTypes } = useSelector(state => state.myRestaurant)
    const [opentime, setopentime] = useState(null)
    const [closetime, setclosetime] = useState(null)
    const [ordertime, setordertime] = useState(null)
    const [preview, setPreview] = useState(myRestaurant?.data?.bannerImage);
    const [profilePreview, setprofilePreview] = useState(myRestaurant?.data?.logoUrl)
    const [isImage, setisImage] = useState(null)
    const [isBanner, setisBanner] = useState(myRestaurant?.data?.bannerImage)
    const [allowImg, setallowImg] = useState(false)

    const [selectedService, setselectedService] = useState([]);
    const [takeinType, settakeinType] = useState(myRestaurant?.data?.takeinSharePerOrderType || []);

    // console.log(takeinType);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMyRestaurant()).then((e) => {
            const data = e?.payload?.data
            setPreview(data?.bannerImage)
            setprofilePreview(data?.logoUrl)
            settakeinType(data?.takeinSharePerOrderType || [])
        })
        dispatch(getTags())
        dispatch(getcuisines())
        dispatch(getMealsType())
        dispatch(getDeliveryTypes())
        dispatch(getBanksList())

    }, [dispatch])

    const { allBanks } = useSelector(state => state?.banks)
    // const [inputValue, setInputValue] = useState('');
    const [bankId, setbankId] = useState('')

    const options = useMemo(() => {
        return allBanks?.data || [];
    }, [allBanks]);


    // useEffect(() => {
    //     setPreview(myRestaurant?.data?.bannerImage)
    //     setprofilePreview(myRestaurant?.data?.logoUrl)
    //     settakeinType(myRestaurant?.data?.takeinSharePerOrderType || [])
    // }, [myRestaurant?.data?.bannerImage, myRestaurant?.data?.logoUrl])



    // console.log(myRestaurant?.data);

    const resTags = useSelector(state => state?.tags) // all tags available
    const defTagsIDs = myRestaurant?.data?.tags?.map((tag) => ({ id: tag.id })) //restaurant tags (default tags)

    const resCuisines = useSelector(state => state?.cuisines) // all cuisine available
    const defCuisine = myRestaurant?.data?.cuisines?.map((cuisine) => ({ id: cuisine.id })) //default cuisines

    const resMealsType = useSelector(state => state?.mealsType) // all meals type available
    const defMealsType = myRestaurant?.data?.mealTypes?.filter((meal) => meal != null).map((meal) => ({ id: meal.id })); //default meals

    const resDeliveryTypes = useSelector(state => state.myRestaurant?.deliveryTypes) // all DeliveryTypes available
    const defDeliveryTypes = myRestaurant?.data?.deliveryTypes?.map((type) => ({ id: type?.deliveryTypeId })) //default DeliveryTypes

    // console.log(myRestaurant?.data);
    useEffect(() => {

        setValue1('name', myRestaurant?.data?.name);
        setValue1('nameAr', myRestaurant?.data?.nameAr);
        setValue1('phoneNumber', myRestaurant?.data?.phoneNumber);
        setValue1('hotline', myRestaurant?.data?.hotline);
        setValue1('email', myRestaurant?.data?.email);
        // setValue1('bankName', myRestaurant?.data?.bankName);
        setValue1('bankId', myRestaurant?.data?.bankId);

        setValue1('bankAccountNumber', myRestaurant?.data?.bankAccountNumber);
        setValue1('minimumCharge', myRestaurant?.data?.minimumCharge);
        setValue1('tags', defTagsIDs || []);
        setValue1('cuisines', defCuisine || []);
        setValue1('mealTypes', defMealsType || []);
        setValue1('deliveryTypes', defDeliveryTypes || []);
        setValue1('bannerImage', myRestaurant?.data?.bannerImage);
        setValue1('logoUrl', myRestaurant?.data?.logoUrl);
        setValue1('acceptsCurbsidePickup', myRestaurant?.data?.acceptsCurbsidePickup?.toString());
        setValue1('acceptsDelivery', myRestaurant?.data?.acceptsDelivery?.toString());
        setValue1('acceptsOnlinePayment', myRestaurant?.data?.acceptsOnlinePayment?.toString());
        setValue1('alwaysOpen', myRestaurant?.data?.alwaysOpen?.toString());
        setValue1('acceptsPromocode', myRestaurant?.data?.acceptsPromocode?.toString());
        setValue1('takeinSharePerOrder', myRestaurant?.data?.takeinSharePerOrder);
        setValue1('takeinSharePerOrderType', myRestaurant?.data?.takeinSharePerOrderType);
        setValue1('openingTime', myRestaurant?.data?.openingTime);
        setValue1('closingTime', myRestaurant?.data?.closingTime);
        setValue1('orderDeadline', myRestaurant?.data?.orderDeadline);
        // setValue('averageDeliveryTime', myRestaurant?.data?.averageDeliveryTime);

    }, [setValue1, myRestaurant]);

    // console.log(myRestaurant?.data);




    const [loadBannerCompress, setloadBannerCompress] = useState(false)

    const maxSize = 1024 * 1024 * 5
    const compressionLimit = 55
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const handleBannerImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // setValue1('bannerImage',file)
            if (file.size > maxSize) {
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('i18nextLng') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if (!acceptedImageTypes.includes(file.type)) {
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                if ((file.size / 1024) > compressionLimit) {
                    setloadBannerCompress(true)
                    new Compressor(file, {
                        quality: 0.6,
                        maxWidth: 600,
                        maxHeight: 600,


                        success: (compressedResult) => {
                            setloadBannerCompress(false)
                            setisBanner(compressedResult)

                            // console.log('compressor',((compressedResult?.size) / (1024 * 1024))?.toFixed(5));

                        },
                    });
                } else {
                    setisBanner(file)
                }
                setallowImg(true)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    // setValue('banner', reader.result);
                    setPreview(reader.result);
                }
            }
        }
    }

    const handleBannerRest = () => {
        setisBanner(null)
        setPreview(null)
        setallowImg(false)
    }

    const [loadImgCompress, setloadImgCompress] = useState(false)

    const handleProfileImageChange = (e) => {

        const file = e.target.files[0];

        if (file) {
            // setValue1('logo',file)
            if (file.size > maxSize) {
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('i18nextLng') === 'ar' ? 'أقصي مساحه للملف 5 ميجا بايت' : 'File size exceeds 5MB',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if (!acceptedImageTypes.includes(file.type)) {
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                if ((file.size / 1024) > compressionLimit) {
                    setloadImgCompress(true)
                    new Compressor(file, {
                        quality: 0.4,
                        maxWidth: 400,
                        maxHeight: 400,

                        success: (compressedResult) => {
                            setloadImgCompress(false)
                            setisImage(compressedResult)
                        },
                    });
                } else {
                    setisImage(file)
                }

                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    // setValue('ResturantProfileImage', reader.result);
                    setprofilePreview(reader.result);
                }
            }
        }
    }

    const handleOfferTypeSelectChange = (event) => {
        const { target: { value }, } = event;
        settakeinType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setValue1('takeinSharePerOrderType', value, { shouldValidate: true })
    };

    // console.log(myRestaurant?.data);
    const [isComponentMounted, setIsComponentMounted] = useState(false)


    // handle tags select

    const [openTag, setOpenTag] = useState(false);

    const handleCloseTag = () => {
        setOpenTag(false);
    };
    const handleOpenTag = () => {
        setOpenTag(true);
    };

    const defaultTagIDs = defTagsIDs?.map(tag => tag.id); // return array of the default ids while defTagsIDS return array of object with the ids
    let defaultTags;
    if (Array.isArray(resTags?.tags)) {
        defaultTags = resTags.tags.filter(tag => defaultTagIDs?.includes(tag.id));
    }

    let tagsList;
    if (Array.isArray(resTags?.tags)) {
        tagsList = resTags?.tags?.map(item => item)
    }


    const [tags, settags] = useState(tagsList?.filter((e) =>
        defaultTags?.some((item) => e.id === item.id)));


    const handleTagsChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;

        settags(
            tagsList.filter((e) =>
                selectedValues.some((item) => e.id === item?.id)
            )
        );

    };
    // console.log(tags);


    // handle cuisines select

    const [openCuisine, setopenCuisine] = useState(false);

    const handleCloseCuisine = () => {
        setopenCuisine(false);
    };
    const handleOpenCuisine = () => {
        setopenCuisine(true);
    };

    const defaultCuisineIDs = defCuisine?.map(cuisine => cuisine.id);
    // const defaultCuisines = resCuisines?.cuisines?.filter(cuisine => defaultCuisineIDs?.includes(cuisine.id));
    let defaultCuisines;
    if (Array.isArray(resCuisines?.cuisines)) {
        defaultCuisines = resCuisines.cuisines.filter(cuisine => defaultCuisineIDs?.includes(cuisine.id));
    }
    // const cuisinesList = resCuisines?.cuisines?.map( item=> item )

    let cuisinesList;
    if (Array.isArray(resCuisines?.cuisines)) {
        cuisinesList = resCuisines?.cuisines?.map(item => item)
    }


    const [cuisines, setcuisines] = useState(cuisinesList?.filter((e) =>
        defaultCuisines?.some((item) => e.id === item.id)));


    const handleCuisinesChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;

        setcuisines(
            cuisinesList.filter((e) =>
                selectedValues.some((item) => e.id === item?.id)
            )
        );

    };

    // console.log(cuisines);


    // useEffect(() => {
    //     if ((defaultCuisines?.length && !isComponentMounted)) {
    //         setIsComponentMounted(true);
    //     setcuisines(defaultCuisines);
    //     }
    // }, [defaultCuisines, isComponentMounted,myRestaurant]);


    // handle MealsType select

    const [openMeals, setopenMeals] = useState(false);

    const handleCloseMeals = () => {
        setopenMeals(false);
    };
    const handleOpenMeals = () => {
        setopenMeals(true);
    };

    const defaultMealsIDs = defMealsType?.map(meal => meal.id);
    // const defaultMeals = resMealsType?.mealsType?.filter(meal => defaultMealsIDs?.includes(meal.id));

    let defaultMeals;
    if (Array.isArray(resMealsType?.mealsType)) {
        defaultMeals = resMealsType?.mealsType?.filter(meal => defaultMealsIDs?.includes(meal.id));
    }

    // const mealsList = resMealsType?.mealsType?.map( item=> item )

    let mealsList;
    if (Array.isArray(resMealsType?.mealsType)) {
        mealsList = resMealsType?.mealsType?.map(item => item)
    }

    const [meals, setmeals] = useState(mealsList?.filter((e) =>
        defaultMeals?.some((item) => e.id === item.id))); // used to handle fetched 




    // console.log(defaultMeals);

    const handleMealsChipSelectChange = (event) => {
        const { target: { value }, } = event;
        const selectedValues = typeof value === 'string' ? value.split(',') : value;

        setmeals(
            mealsList.filter((e) =>
                selectedValues.some((item) => e.id === item?.id)
            )
        );
    };

    // useEffect(() => {
    //     if ((defaultMeals?.length && !isComponentMounted)) {
    //         setIsComponentMounted(true);
    //     setmeals(defaultMeals);
    //     }
    // }, [defaultMeals, isComponentMounted,myRestaurant]);

    useEffect(() => {
        if (Array.isArray(resTags.tags) && myRestaurant.data) {
            settags(defaultTags);
            setIsComponentMounted(true);
        }

        if (Array.isArray(resCuisines.cuisines) && myRestaurant.data) {
            setcuisines(defaultCuisines);
            setIsComponentMounted(true);
        }

        if (Array.isArray(resMealsType.mealsType) && myRestaurant.data) {
            setmeals(defaultMeals);
            setIsComponentMounted(true);
        }

    }, [resTags, resCuisines, resMealsType, myRestaurant]);

    useEffect(() => {
        // console.log(getValues());
        // console.log(meals);
        // console.log(cuisines);
    }, [meals, profilePreview, cuisines, preview, errors, setValue1, getValues1, opentime, closetime, ordertime])

    // const seriveHandler = (e)=>{
    //     // console.log(e.target.value);
    //     let val = e.target.value;
    //     const isChecked = e.target.checked;
    //     const currentDeliveryTypes = getValues1('deliveryTypes') || [];

    //     // let updatedDeliveryTypes = [...currentDeliveryTypes]; // Clone the array
    //     let updatedDeliveryTypes = currentDeliveryTypes.filter((type) => type.id !== val);

    //     if (isChecked) {
    //         updatedDeliveryTypes.push({ id: parseInt(val) }); // Add new object
    //     } else {
    //         const index = updatedDeliveryTypes.findIndex((type) => type.id === parseInt(val));
    //         if (index !== -1) {
    //             updatedDeliveryTypes.splice(index, 1); // Remove object
    //         }
    //     }
    //     // console.log(updatedDeliveryTypes);
    //     setValue1('deliveryTypes', updatedDeliveryTypes); // Update form state
    // }

    // useEffect(() => {
    //     if ((defDeliveryTypes?.length && !isComponentMounted)) {
    //         setIsComponentMounted(true);
    //     }
    // }, [deliveryTypes,defDeliveryTypes, isComponentMounted,myRestaurant]);

    // console.log(myRestaurant);

    const submitHandler = async (data) => {
        // const cuisineJsonString = JSON.stringify(cuisines?.map(item=>( {id:item?.id} )))
        // const mealJsonString = JSON.stringify(meals?.map(item=>( {id:item?.id} )))
        // const deliveryTypes = JSON.stringify(getValues1('deliveryTypes')?.map(item=>( {id:item?.id} )))

        const tagArray = tags?.map(item => ({ id: item?.id }))
        const cuisineArray = cuisines?.map(item => ({ id: item?.id }))
        const mealArray = meals?.map(item => ({ id: item?.id }))
        // const deliveryTypeJsonString = getValues1('deliveryTypes')?.map(item=>( {id:item?.id} ))

        let updatedData

        let ImageResponse = null,
            bannerResponse = null

        const image = new FormData()
        image.append('file', isImage)

        const banner = new FormData()
        banner.append('file', isBanner)

        if (isImage !== null) {
            ImageResponse = await dispatch(addFile(image));
        }

        if (isBanner !== null && allowImg === true) {
            bannerResponse = await dispatch(addFile(banner))

        }

        updatedData = {
            ...data, // include all other form data
            logoUrl: ImageResponse?.payload?.data ? ImageResponse?.payload?.data?.url : myRestaurant?.data?.logoUrl,
            bannerImage: bannerResponse?.payload?.data ? bannerResponse?.payload?.data?.url : isBanner === null ? null : myRestaurant?.data?.bannerImage,
            tags: tagArray, // convert tags array to JSON string
            cuisines: cuisineArray, // convert cuisine array to JSON string
            mealTypes: mealArray, // convert meals array to JSON string,
            // deliveryTypes : deliveryTypeJsonString, // convert delivery types array to JSON string,
        }

        // const keysToUpdate = [
        //     "acceptsCurbsidePickup",
        //     "acceptsDelivery",
        //     "acceptsOnlinePayment",
        //     "acceptsPromocode",
        //     "alwaysOpen"
        // ];

        // keysToUpdate.forEach((key) => {
        //     if (data.hasOwnProperty(key)) {
        //       updatedData[key] = data[key] === "true"; // Convert the string value to boolean
        //     }
        // });

        const formData = {
            ...updatedData, // include all other form data
        };

        // let openTime = new Date(`1970-01-01T${formData?.openingTime}:00Z`)
        // let closeTime = new Date(`1970-01-01T${formData?.closingTime}:00Z`)

        // console.log(openTime > closeTime);

        // use this condition to check if close time not before open

        // if(openTime > closeTime){
        //     toast.error('Close Time Cannot be before open time')
        // } else {
        //     await dispatch(editMyRestaurant(formData)).then( (e)=>{
        //         if(e?.payload?.message === 'SUCCESS'){
        //             dispatch(getMyRestaurant())
        //         }
        //     } )
        // }

        await dispatch(editMyRestaurant(formData)).then((e) => {
            if (e?.payload?.message === 'SUCCESS') {
                dispatch(getMyRestaurant())
            }
        })

        // let formData = new FormData()

        // Object.keys(data).forEach((key) => {
        //     if (key === "acceptsCurbsidePickup" || key === "acceptsDelivery" || key === "acceptsOnlinePayment" || key === "acceptsPromocode" || key === "alwaysOpen") {
        //         formData.append(key, Boolean(data[key]));
        //     } else if (key === "cuisines") {
        //         formData.append(key, cuisineJsonString);
        //     } else if (key === "mealTypes") {
        //         formData.append(key, mealJsonString);
        //     } else if (key === "deliveryTypes") {
        //         formData.append(key, deliveryTypes);
        //     } else if (key === "logoUrl") {
        //         formData.append(key, ImageResponse?.payload?.data?.url);
        //     } else if (key === "bannerImage") {
        //         formData.append(key, bannerResponse?.payload?.data?.url);
        //     } else {
        //         formData.append(key, data[key]);
        //     }
        // });

        // console.log(ImageResponse);
        // console.log(bannerResponse);

        // for(var pair of formData.entries()) {
        //     console.log(pair[0]+ ', '+ pair[1]);
        // }
    }

    const submitTimeHandler = (data) => {
        // console.log(data);
        dispatch(setWorkingHours(data))
    }

    // console.log(myRestaurant?.data);

    // useEffect(() => {
    //     // console.log(opentime);
    //     // let openingTime = opentime ? opentime?.$H + ":" + opentime?.$m + ":" + opentime?.$s : null
    //     let openingTime = opentime ? new Date(opentime) : null
    //     let closingTime = closetime ? new Date(closetime) : null
    //     let orderTime = ordertime ? new Date(ordertime) : null
    //     setValue1('openingTime',openingTime?.toTimeString()?.slice(0,8))
    //     setValue1('closingTime',closingTime?.toTimeString()?.slice(0,8))
    //     setValue1('orderDeadline',orderTime?.toTimeString()?.slice(0,8))

    //     // console.log(getValues1());
    // }, [opentime,closetime,ordertime])


    useEffect(() => {
        let openingTime = opentime ? new Date(opentime) : null
        setValue1('openingTime', openingTime?.toTimeString()?.slice(0, 5))
    }, [opentime, setValue1])

    useEffect(() => {
        let closingTime = closetime ? new Date(closetime) : null
        setValue1('closingTime', closingTime?.toTimeString()?.slice(0, 5))
    }, [closetime, setValue1])

    useEffect(() => {
        let orderTime = ordertime ? new Date(ordertime) : null
        setValue1('orderDeadline', orderTime?.toTimeString()?.slice(0, 5))
    }, [ordertime, setValue1])

    useEffect(() => {
        // console.log(selectedService);
    }, [selectedService])

    const { file } = useSelector(state => state)
    const resLoading = useSelector(state => state.myRestaurant)

    // useEffect(() => {
    //     // Highlight pre-selected values on mount
    //     if (defaultTags?.length) {
    //       settags(defaultTags); // Set pre-selected tags directly
    //     }
    //     if (defaultCuisines?.length) {
    //       setcuisines(defaultCuisines); // Set pre-selected cuisines directly
    //     }
    //     if (defaultMeals?.length) {
    //       setmeals(defaultMeals); // Set pre-selected meals directly
    //     }
    //   }, [defaultTags, defaultCuisines, defaultMeals]);


    // console.log(tags)
    return (
        <article className="settings-wrapper pb-4">
            <section className="setting-title-wrapper mb-5">
                <h2 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('settings.title')}</h2>
            </section>

            <div className="setting-form ">
                <div className="relative" >
                    <form onSubmit={handleSubmitForm1(submitHandler)} className="setting-left-section flex flex-wrap lg:flex-nowrap gap-5 rounded-xl relative overflow-hidden">
                        <div className="basis-full lg:basis-[55%] bg-white rounded-xl overflow-hidden">
                            <div className="resturant-preview-wrappe w-full h-[200px] relative">
                                <div className="defaultImage w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,.3)] flex items-center justify-center">
                                    <img src={previewIcon} alt="default" className="w-[150px] h-[150px] m-auto object-cover" />
                                </div>

                                <div className="banner-preview-wrapper w-full h-full relative">
                                    <div className="absolute top-0 right-0 cursor-pointer w-full h-full">
                                        <div className="w-full h-full ml-auto relative cursor-pointer">
                                            <input type="file" id="file" accept="image/*" onChange={handleBannerImageChange} className="w-full h-full absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0 z-10 opacity-1" />
                                            {/* <span className="block absolute right-0 text-sm bg-[rgba(22,22,22,0.38)] p-1 text-white cursor-pointer capitalize w-full h-full rounded-md text-center">edit banner image</span> */}
                                            <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                            <span onClick={handleBannerRest} className="block absolute bottom-0 right-0 text-2xl bg-[#fff] bg-opacity-30 p-1 text-mainRed cursor-pointer z-[99]"><BiReset /></span>

                                        </div>
                                    </div>
                                    <img src={preview ? preview : bannerDef} alt="Preview" className="w-full h-full object-cover" />

                                    {loadBannerCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                        <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                    </div> : null}
                                </div>

                                <div className="resturant-profile-image-preview absolute -bottom-[33%] w-[150px] h-[150px] bg-[rgba(0,0,0,.3)] left-6 rounded-lg z-50">
                                    <div className="defaultImage w-full h-full z-10 absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center rounded-lg">
                                        <img src={previewIcon} alt="default" className="w-[100px] h-[100px] m-auto object-cover" />
                                    </div>
                                    <div className="preview-wrapper shadow-md relative rounded-lg z-30 w-full h-full">
                                        <div className="absolute top-0 right-0 w-full h-full rounded-lg ">
                                            <input type="file" id="file" accept="image/*" onChange={handleProfileImageChange} className="absolute right-0 cursor-pointer opacity-1 z-10 opacity-0 w-full h-full" />
                                            <span className="block absolute right-0 text-2xl bg-[#F2EEFC] p-1 rounded-md text-mainColor cursor-pointer"><MdOutlineModeEditOutline /></span>
                                        </div>
                                        {profilePreview && <img src={profilePreview} alt="Resturant Profile" className="w-full h-full rounded-lg object-cover" />}

                                        {loadImgCompress ? <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-[rgba(0,0,0,.4)] text-white flex items-center justify-center">
                                            <p className="w-fit animate-spin text-3xl"><AiOutlineLoading3Quarters /></p>
                                        </div> : null}
                                    </div>
                                </div>

                            </div>

                            <div className="form-input-wrapper mt-24 px-6 flex flex-wrap justify-start lg:justify-between [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('settings.form.name_en')} <Astrisks /></label>
                                    <input type="text" id="name"
                                        defaultValue={myRestaurant?.data?.name}
                                        {...register('name', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^[a-zA-Z+\s#&]{1,50}$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزية والارقام والمسافات وعلامه (+, &, #) فقط' : 'Only English letters, numbers, (+, &, #) or spaces allowed'
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('common.min_length', { length: '3' })
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('common.max_length', { length: '50' })
                                            },
                                        })}
                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.name_en_helper')}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('settings.form.name_ar')} <Astrisks /></label>
                                    <input type="text" id="nameAr"
                                        defaultValue={myRestaurant?.data?.nameAr}
                                        {...register('nameAr', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^[\u0621-\u064A\s&0-9]+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف العربية والارقام والمسافات وعلامه & فقط' : 'Only Arabic letters, numbers, spaces, & sign are allowed'
                                            },
                                            minLength: {
                                                value: 3,
                                                message: t('common.min_length', { length: '3' })
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: t('common.max_length', { length: '50' })
                                            },
                                        })}

                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.name_ar_helper')}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('settings.form.email')} <Astrisks /></label>
                                    <input type="text" id="email"
                                        defaultValue={myRestaurant?.data?.email}
                                        {...register('email', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'خطا بصيغه الايميل' : 'Wrong Email Format'
                                            },
                                        })}
                                    />

                                    {errors?.email?.message ? <p className="capitalize text-sm text-danger">{errors?.email?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.email_helper')}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                    <label>{t('settings.form.phone')} <Astrisks /></label>

                                    <TextField
                                        type="text"
                                        id="phoneNumber"
                                        defaultValue={myRestaurant?.data?.phoneNumber}
                                        {...register('phoneNumber', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^(\+)?\d+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف بالارقام وعلامه + بالبداية فقط' : 'Only numbers and (+) sign the beginning allowed'
                                            }
                                        })}
                                        // label="With normal TextField"
                                        // sx={{ m: 1, width: '25ch', }}
                                        sx={{ '& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '& fieldset': { border: 0 }, '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': { backgroundColor: '#f3f6f9' }, width: '100%', '& .css-1t5rzfh': { backgroundColor: '#f3f6f9' } }}
                                    // InputProps={{
                                    //     endAdornment: <InputAdornment position="start" className="pl-2">SAR</InputAdornment>,
                                    // }}
                                    />

                                    {errors?.phoneNumber?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.phoneNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">{t('settings.form.phone_helper')}</p>}
                                </div>

                                <div className="basis-full [&>div]:bg-[#f3f6f9]">
                                    <label>{t('settings.form.tags')} </label>
                                    <Controller
                                        name="tags"
                                        control={control}
                                        defaultValue=""
                                        // rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <Select
                                                    open={openTag}
                                                    onOpen={handleOpenTag}
                                                    onClose={handleCloseTag}
                                                    labelId="tags-chip-select-label"
                                                    id="tags"
                                                    multiple
                                                    className="w-full mb-1"
                                                    value={tags || []}
                                                    onChange={(e) => {
                                                        if (e?.target?.value?.length <= 30) {
                                                            handleTagsChipSelectChange(e);
                                                            onChange(e);
                                                        } else {
                                                            toast.error('Maximum Tags Selection is 30')
                                                        }
                                                    }}
                                                    sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' } }}
                                                    // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected?.map((value) => (
                                                                value ? <Chip key={value?.id} label={value?.name} className="bg-[#464E5F] text-white font-light rounded-lg disabled:text-white disabled:bg-white" /> : null
                                                            ))}
                                                        </Box>
                                                    )}
                                                // MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCloseTag();
                                                        }}>
                                                            Confirm
                                                        </Button>
                                                    </MenuItem>
                                                    {tagsList?.map((name, index) => (
                                                        <MenuItem
                                                            key={name?.id}
                                                            value={name}
                                                            selected={index === 0}
                                                        >
                                                            {name?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.tags_helper')}</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full [&>div]:bg-[#f3f6f9]">
                                    <label>{t('settings.form.meal_type')} <Astrisks /></label>
                                    <Controller
                                        name="mealTypes"
                                        control={control}
                                        defaultValue={[]}
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <Select
                                                    open={openMeals}
                                                    onOpen={handleOpenMeals}
                                                    onClose={handleCloseMeals}
                                                    labelId="mealtypes-chip-select-label"
                                                    id="mealTypes"
                                                    multiple
                                                    className="w-full mb-1"
                                                    value={meals || []}
                                                    onChange={(e) => {
                                                        handleMealsChipSelectChange(e);
                                                        onChange(e);
                                                    }}
                                                    sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' } }}
                                                    // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected?.map((value) => (
                                                                value ? <Chip key={value?.id} label={localStorage.getItem('i18nextLng') === 'ar' ? value?.nameAr : value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                            ))}
                                                        </Box>
                                                    )}
                                                // MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCloseMeals();
                                                        }}>
                                                            Confirm
                                                        </Button>
                                                    </MenuItem>
                                                    {mealsList?.map((name) => (
                                                        <MenuItem
                                                            key={name?.id}
                                                            value={name}
                                                        >
                                                            {name?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.meal_type_helper')}</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full [&>div]:bg-[#f3f6f9]">
                                    <label>{t('settings.form.cuisine')} <Astrisks /></label>
                                    <Controller
                                        name="cuisines"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <Select
                                                    open={openCuisine}
                                                    onOpen={handleOpenCuisine}
                                                    onClose={handleCloseCuisine}
                                                    labelId="cuisines-chip-select-label"
                                                    id="cuisines"
                                                    multiple
                                                    className="w-full mb-1"
                                                    value={cuisines || []}
                                                    onChange={(e) => {
                                                        handleCuisinesChipSelectChange(e);
                                                        onChange(e);
                                                    }}
                                                    sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-bamf9v': { backgroundColor: '#f3f6f9' } }}
                                                    // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                value ? <Chip key={value?.id} label={localStorage.getItem('i18nextLng') === 'ar' ? value?.nameAr : value?.name} className="bg-[#464E5F] text-white font-light rounded-lg" /> : null
                                                            ))}
                                                        </Box>
                                                    )}
                                                // MenuProps={MenuProps}
                                                >
                                                    <MenuItem>
                                                        <Button className="w-full text-center bg-mainColor text-white capitalize" onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCloseCuisine();
                                                        }}>
                                                            Confirm
                                                        </Button>
                                                    </MenuItem>
                                                    {cuisinesList?.map((name) => (
                                                        <MenuItem
                                                            key={name?.id}
                                                            value={name}
                                                        >
                                                            {name?.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.cuisine_helper')}</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('settings.form.takein_share')} <Astrisks /></label>
                                    <input type="text" id="takeinSharePerOrder"
                                        {...register('takeinSharePerOrder', {
                                            required: {
                                                value: true,
                                                message: t('common.required')
                                            },
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالارقام فقط' : 'Only Digits Allowed'
                                            },
                                        })}
                                        readOnly
                                        disabled
                                    />
                                    {errors?.takeinSharePerOrder?.message ? <p className="capitalize text-sm text-danger">{errors?.takeinSharePerOrder?.message}</p> : null}
                                </div>

                                <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                    <label> {t('settings.form.takein_type')} <Astrisks /></label>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name=""
                                            value={takeinType}
                                            rules={{ required: "Choose at least one Option" }}
                                            {...register('takeinSharePerOrderType', {
                                                required: {
                                                    value: true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            render={({ field, fieldState: { error } }) => (
                                                <>
                                                    <Select {...field}
                                                        displayEmpty
                                                        inputRef={{ ...register('takeinSharePerOrderType') }}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="takeinSharePerOrderType"
                                                        value={takeinType}
                                                        onChange={handleOfferTypeSelectChange}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        disabled
                                                        readOnly
                                                        sx={{ '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '11px 14px' }, '& fieldset': { border: 0 }, '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { backgroundColor: '#f3f6f9' }, '& .css-tzsjye': { backgroundColor: '#f3f6f9' } }}
                                                    >

                                                        <MenuItem value={'AMOUNT'}>
                                                            <ListItemText primary={'Amount'} />
                                                        </MenuItem>

                                                        <MenuItem value={'PERCENTAGE'}>
                                                            <ListItemText primary={'Percentage'} />
                                                        </MenuItem>
                                                    </Select>
                                                </>
                                            )}

                                        />

                                    </FormControl>

                                    {errors?.takeinSharePerOrderType?.message ? <p className="capitalize text-sm text-danger">{errors?.takeinSharePerOrderType?.message}</p> : null}
                                </div>

                                {/* <div className="basis-full lg:basis-[48%]">
                                    <label>Delivery Minimum Fees</label>

                                    <TextField
                                        type="number"
                                        id="minDelivery"
                                        {...register('minDelivery',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            valueAsNumber : true
                                        })}
                                        // label="With normal TextField"
                                        // sx={{ m: 1, width: '25ch', }}
                                        sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'},width:'100%'}}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start" className="pl-2">SAR</InputAdornment>,
                                        }}
                                    />

                                    {errors?.minDelivery?.message ? <p className="capitalize text-sm text-danger">{errors?.minDelivery?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">Please Type the Minimum Delivery Fees</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>Delivery Maximum Fees</label>

                                    <TextField
                                        type="number"
                                        id="maxDelivery"
                                        {...register('maxDelivery',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            valueAsNumber : true
                                        })}
                                        // label="With normal TextField"
                                        // sx={{ m: 1, width: '25ch', }}
                                        sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'},width:'100%'}}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start" className="pl-2">SAR</InputAdornment>,
                                        }}
                                    />

                                    {errors?.maxDelivery?.message ? <p className="capitalize text-sm text-danger">{errors?.maxDelivery?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">Please Type the Maximum Delivery Fees</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>minimum charge <Astrisks /></label>
                                    <input type="number" id="minimumCharge"
                                        {...register('minimumCharge',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            }
                                        })}
                                        min={0}
                                        defaultValue={myRestaurant?.data?.minimumCharge}
                                    />

                                    {errors?.minimumCharge?.message ? <p className="capitalize text-sm text-danger">{errors?.minimumCharge?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter minimum charge</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label htmlFor="select">Always Open <Astrisks /></label>
                                    <Controller
                                        name="alwaysOpen"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                            <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                                <option value="" disabled>
                                                    Select an option
                                                </option>
                                                <option value={'true'}>yes</option>
                                                <option value={'false'}>no</option>
                                            </select>
                                            {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label htmlFor="selectCurbside">accepts curbside pickup <Astrisks /></label>
                                    <Controller
                                        name="acceptsCurbsidePickup"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                            <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                                <option value="" disabled>
                                                    Select an option
                                                </option>
                                                <option value={'true'}>yes</option>
                                                <option value={'false'}>no</option>
                                            </select>
                                            {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label htmlFor="acceptsDelivery">accepts delivery <Astrisks /></label>
                                    <Controller
                                        name="acceptsDelivery"
                                        control={control}
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            
                                            <>
                                            <select 
                                                onChange={(e) => { onChange(e)}} 
                                                value={value} 
                                                className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1"
                                            >

                                                <option value="" disabled>
                                                    Select an option
                                                </option>
                                                <option value={"true"}>yes</option>
                                                <option value={"false"}>no</option>

                                            </select>
                                            {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label htmlFor="onlinePayment">accepts online payment <Astrisks /></label>
                                    <Controller
                                        name="acceptsOnlinePayment"
                                        control={control}
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                            <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                                <option value="" disabled>
                                                    Select an option
                                                </option>
                                                <option value={"true"}>yes</option>
                                                <option value={"false"}>no</option>
                                            </select>
                                            {error ? <p className="capitalize text-sm text-danger">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please select option</p>}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label htmlFor="acceptsPromocode">accepts promo code <Astrisks /></label>
                                    <Controller
                                        name="acceptsPromocode"
                                        control={control}
                                        rules={{ required: t('common.required') }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                            <select onChange={(e) => { onChange(e)}} value={value} className="w-full bg-[#f3f6f9] p-3 rounded-md text-mainColor capitalize mb-1">
                                                <option value="" disabled>
                                                    Select an option
                                                </option>
                                                <option value={"true"}>yes</option>
                                                <option value={"false"}>no</option>
                                            </select>
                                            {error ? <p className="capitalize text-sm text-danger mt-1">{error.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">please select option</p>}
                                            </>
                                        )}
                                    />
                                </div> 

                                <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                    <label>Restaurant Hotline <Astrisks /></label>

                                    <TextField
                                        type="text"
                                        id="hotline"
                                        defaultValue={myRestaurant?.data?.hotline}
                                        {...register('hotline',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            minLength : {
                                                value : 5,
                                                message : 'min length is 5 digits'
                                            },
                                            maxLength : {
                                                value : 11,
                                                message : 'max length is 11 digits'
                                            },
                                            pattern:{
                                                value: /^(\+)?\d+$/,
                                                message: 'Only numbers and (+) sign the beginning allowed'
                                            }
                                        })}
                                        minLength={5}
                                        maxLength={11}
                                        // label="With normal TextField"
                                        // sx={{ m: 1, width: '25ch', }}
                                        sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'},width:'100%','& .css-1t5rzfh' : {backgroundColor : '#f3f6f9'}}}
                                        // InputProps={{
                                        //     endAdornment: <InputAdornment position="start" className="pl-2">SAR</InputAdornment>,
                                        // }}
                                    />

                                    {errors?.hotline?.message ? <p className="capitalize text-sm text-danger mt-1">{errors?.hotline?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3] mt-1">Please Type the hotline</p>}
                                </div> */}

                            </div>
                        </div>

                        <div className="basis-full lg:basis-[45%]">
                            {/* <section className="resturant-service-wrapper bg-white p-6 pb-6 rounded-lg mt-0 mb-5  h-fit">
                                <div className="resturant-service-title-wrapper mb-5">
                                    <h2 className="capitalize font-bold text-xl text-[#333]">Restaurant Services <Astrisks /></h2>
                                </div>

                                <div className="resturant-service-list-wrapper">
                                    
                                    <ul>
                                        {isComponentMounted && deliveryTypes?.data?.map((item) => (
                                            <li key={item.id} className="mb-2">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={(e) => seriveHandler(e)}
                                                            value={item.id}
                                                            defaultChecked={defDeliveryTypes?.some((defaultType) => defaultType?.id === item.id)}
                                                        />
                                                    }
                                                    label={item.name}
                                                />
                                                {console.log(defDeliveryTypes?.some((defaultType) => defaultType?.id === item.id))}
                                            </li>
                                        ))}
                                                
                                    </ul>
                                    {errors?.check?.message ? <p className="capitalize text-sm text-danger">{errors?.check?.message}</p> : null}
                                </div>


                                <div className="basis-full mt-5 text-right">
                                    <Button type="submit" disabled={file?.loading || resLoading?.loading } className="disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainGreen text-white w-[250px] py-3 text-lg font-semibold">save</Button>
                                </div> 
                            </section> */}

                            <section>
                                <div className="w-full flex flex-col justify-between">
                                    <section className="restaurant-bank-accounts bg-white p-6 rounded-lg mb-4">

                                        <div className="timing-title-wrapper mb-5">
                                            <h2 className="capitalize font-bold text-xl text-[#333]">{t('settings.form.bank_details')}</h2>
                                        </div>

                                        <div className="flex flex-wrap justify-start lg:justify-between [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                                            {/* <div className="basis-full lg:basis-[48%]">
                                                <label>{t('settings.form.bank_name')} <Astrisks /></label>
                                                <input type="text" id="bankName"
                                                    defaultValue={myRestaurant?.data?.bankName}
                                                    {...register('bankName', {
                                                        required: {
                                                            value: true,
                                                            message: t('common.required')
                                                        },
                                                        maxLength: {
                                                            value: 60,
                                                            message: t('common.max_length', { length: '60' })
                                                        },
                                                    })}
                                                    maxLength={60}
                                                />

                                                {errors?.bankName?.message ? <p className="capitalize text-sm text-danger">{errors?.bankName?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.bank_name_helper')}</p>}
                                            </div> */}

                                            <div className="basis-full lg:basis-[48%]">
                                                <label>{t('settings.form.bank_name')} <Astrisks /></label>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <Controller
                                                        control={control}
                                                        name="bankId"
                                                        // value={bankId}
                                                        rules={{ required: t('common.required') }}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <>
                                                                <Autocomplete
                                                                    sx={{ '.MuiTextField-root': { backgroundColor: "#f3f6f9", padding: '0px', borderRadius: "6px" }, 'fieldset': { border: 'none' }, '.MuiOutlinedInput-root': { padding: "5px" } }}
                                                                    {...field}
                                                                    options={options}
                                                                    getOptionLabel={(option) =>
                                                                        localStorage.getItem('lang') === 'ar' ? option?.nameAr || '' : option?.name || ''
                                                                    }
                                                                    value={options.find((option) => option.id === field.value) || null}
                                                                    onChange={(_, newValue) => {
                                                                        field.onChange(newValue?.id || null);
                                                                    }}
                                                                    inputValue={bankId}
                                                                    onInputChange={(_, newInputValue) => {
                                                                        setbankId(newInputValue);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            // label={t('bank_name')}
                                                                            error={!!error}
                                                                            helperText={error?.message}
                                                                        />
                                                                    )}
                                                                />
                                                            </>
                                                        )}

                                                    />

                                                </FormControl>

                                                {errors?.bankId?.message ? <p className="capitalize text-danger">{errors?.bankId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.bank_name_helper')} </p>}
                                            </div>

                                            <div className="basis-full lg:basis-[48%]">
                                                <label>{t('settings.form.bank_number')} <Astrisks /></label>
                                                <input type="text" id="bankAccountNumber"
                                                    defaultValue={myRestaurant?.data?.bankAccountNumber}
                                                    {...register('bankAccountNumber', {
                                                        required: {
                                                            value: true,
                                                            message: t('common.required')
                                                        },
                                                        validate: value => isValidIBAN(value) || 'Invalid IBAN'
                                                    })}
                                                />

                                                {errors?.bankAccountNumber?.message ? <p className="capitalize text-sm text-danger">{errors?.bankAccountNumber?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.bank_number_helper')}</p>}
                                            </div>

                                        </div>
                                    </section>

                                    <section className="resturant-timing-wrapper bg-white p-6 pb-6 rounded-lg">
                                        <div className="timing-title-wrapper mb-5">
                                            <h2 className="capitalize font-bold text-xl text-[#333]">{t('settings.form.restaurant_timing')}</h2>
                                        </div>

                                        <div className="timing-inputs-wrapper flex flex-wrap justify-start lg:justify-between [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                                            <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                                <label>{t('settings.form.open_time')} <Astrisks /></label>
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker views={['hours', 'minutes']}
                                                        // label="Opening Time"
                                                        {...register('openingTime',{
                                                            required:{
                                                                value : true,
                                                                message : t('common.required')
                                                            },
                                                        })}
                                                        value={opentime || { hour: 0, minute: 0}}
                                                        onChange={(e)=>setopentime(e)}
                                                        name='openingTime'
                                                        className="w-full"
                                                        id='openingTime'
                                                        // defaultValue={myRestaurant?.data?.openingTime}
                                                    />
                                                </LocalizationProvider> */}

                                                <Controller
                                                    name={'openingTime'}
                                                    control={control}
                                                    // defaultValue={def} 
                                                    rules={{ required: t('common.required') }}
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                views={["hours", "minutes"]}
                                                                // label="Opening Time"
                                                                value={value ? dayjs(value, "HH:mm") : null}
                                                                sx={{ '& fieldset': { border: 0, borderColor: 'transparent' }, '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': { backgroundColor: '#f3f6f9' } }}
                                                                onChange={(newValue) => {
                                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                                    // console.log(timeString);
                                                                    onChange(timeString);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={!!error}
                                                                        helperText={error ? error.message : null}
                                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />

                                                {errors?.openingTime?.message ? <p className="capitalize text-sm text-danger">{errors?.openingTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.open_time_helper')}</p>}
                                            </div>

                                            <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                                <label>{t('settings.form.close_time')} <Astrisks /></label>
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker views={['hours', 'minutes']}
                                                        // label="Closing Time"
                                                        {...register('closingTime',{
                                                            required:{
                                                                value : true,
                                                                message : t('common.required')
                                                            },
                                                        })}
                                                        value={closetime || { hour: 0, minute: 0}}
                                                        onChange={(e)=>setclosetime(e)}
                                                        name='closingTime'
                                                        className="w-full"
                                                        id='closingTime'
                                                    />
                                                </LocalizationProvider> */}

                                                <Controller
                                                    name={'closingTime'}
                                                    control={control}
                                                    // defaultValue={def} 
                                                    rules={{ required: t('common.required') }}
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                views={["hours", "minutes"]}
                                                                // label="Opening Time"
                                                                value={value ? dayjs(value, "HH:mm") : null}
                                                                sx={{ '& fieldset': { border: 0, borderColor: 'transparent' }, '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': { backgroundColor: '#f3f6f9' } }}
                                                                onChange={(newValue) => {
                                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                                    // console.log(timeString);
                                                                    onChange(timeString);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={!!error}
                                                                        helperText={error ? error.message : null}
                                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />

                                                {errors?.closingTime?.message ? <p className="capitalize text-sm text-danger">{errors?.closingTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.close_time_helper')}</p>}
                                            </div>

                                            {/* <div className="basis-full lg:basis-[48%]">
                                                <label>delivery time</label>
                                                <input type="number" id="averageDeliveryTime"
                                                    defaultValue={myRestaurant?.data?.averageDeliveryTime}
                                                    {...register('averageDeliveryTime',{
                                                        required:{
                                                            value : true,
                                                            message : t('common.required')
                                                        },
                                                        valueAsNumber : true
                                                    })} 
                                                    min={0}
                                                />

                                                {errors?.averageDeliveryTime?.message ? <p className="capitalize text-sm text-danger">{errors?.averageDeliveryTime?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">please enter delivery time</p>}
                                            </div> */}

                                            <div className="basis-full lg:basis-[100%] [&>div]:w-full [&>div]:bg-[#f3f6f9]">
                                                <label>{t('settings.form.last_order_time')} <Astrisks /></label>
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <TimePicker views={['hours', 'minutes']}
                                                            // label="Last Order Time"
                                                            {...register('orderDeadline',{
                                                                required:{
                                                                    value : true,
                                                                    message : t('common.required')
                                                                },
                                                            })}
                                                            value={ordertime || { hour: 0, minute: 0}}
                                                            onChange={(e)=>setordertime(e)}
                                                            name='orderDeadline'
                                                            className="w-full"
                                                            id='orderDeadline'
                                                        />
                                                </LocalizationProvider> */}

                                                <Controller
                                                    name={'orderDeadline'}
                                                    control={control}
                                                    // defaultValue={def} 
                                                    rules={{ required: t('common.required') }}
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                views={["hours", "minutes"]}
                                                                // label="Opening Time"
                                                                value={value ? dayjs(value, "HH:mm") : null}
                                                                sx={{ '& fieldset': { border: 0, borderColor: 'transparent' }, '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': { backgroundColor: '#f3f6f9' } }}
                                                                onChange={(newValue) => {
                                                                    const timeString = newValue ? dayjs(newValue).format("HH:mm") : null;
                                                                    // console.log(timeString);
                                                                    onChange(timeString);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        error={!!error}
                                                                        helperText={error ? error.message : null}
                                                                    // inputProps={{...params.inputProps, readOnly: true}}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />

                                                {errors?.orderDeadline?.message ? <p className="capitalize text-sm text-danger">{errors?.orderDeadline?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('settings.form.last_order_time_helper')}</p>}
                                            </div>

                                        </div>
                                    </section>

                                </div>

                                <section className={`setting-submit-btn-wrapper basis-full flex justify-end mt-5 ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                                    <div>
                                        <Button type="submit" disabled={file?.loading || resLoading?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed capitalize bg-mainGreen text-white w-[250px] py-3 text-lg font-semibold">{t('settings.form.save_btn')}</Button>
                                    </div>
                                </section>
                            </section>
                        </div>

                        {file?.loading || resLoading?.loading ? <Overlay /> : null}

                    </form>


                </div>


            </div>

        </article>
    );
}

export default Settings;